form .form-control .error {
  color: #e63946;
  font-size: 14px;
}
.otpform .error {
  margin-bottom: 0;
}
@media (max-width: 600px) {
  form .form-control .error {
    color: #e63946;
    font-size: 10px;
  }
}
