.dashboard {
  width: 100vw;
  /* height: 100vh; */
}
.dashboard-header {
  background-color: var(--getmetag-theme);
  color: #ffffff;
}
.dashboard-header .container {
  width: 90%;
  padding: 1rem 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.username {
  text-transform: capitalize;
  font-size: large;
  letter-spacing: 0.1rem;
  position: relative;
  animation: fade-in 1.5s ease-in 1;
  animation-delay: 0.2s;
}
@keyframes fade-in {
  0% {
    left: -150px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}
.dashboard-header .container > * {
  margin-bottom: 0.2rem;
}
.dashboard-header .container button {
  margin: 0 0 1rem auto;
  padding: 0.2rem 0.5rem;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
  text-transform: capitalize;
  background-color: var(--button-hover);
  transition: all 1s ease-out;
}
.dashboard-header .container button:hover {
  outline: 1px solid #ffffff;
}
.dashboard-body {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-body .container {
  width: 90%;
  margin: 1rem auto 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2rem;
}
.dashboard-body .container img {
  margin-bottom: 1rem;
}
.dashboard-body .container > div,
.create-tag-link {
  padding: 1.5rem;
  border: 1px solid #f2f3f7;
  border-radius: 6px;
}
.dashboard-body .section-1 img {
  border-radius: 4px;
  background-color: var(--getmetag-theme);
}
.dashboard-body .heading {
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--getmetag-theme);
}
.create-tag-link {
  text-decoration: none;
}
.create-tag-link .section-desc {
  color: var(--text-color);
}
.coming-soon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.section-2,
.section-2 .heading,
.section-4,
.section-4 .heading {
  color: var(--text-color);
  cursor: not-allowed;
  filter: grayscale(100%)
}

.loader {
  display: flex;
}
.circle {
  animation: blink 2s ease-in-out infinite;
}
.loader .circle:nth-of-type(1) {
  animation-delay: 0.4s;
}
.loader .circle:nth-of-type(2) {
  animation-delay: 0.5s;
}

.loader .circle:nth-of-type(3) {
  animation-delay: 0.6s;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
@media (max-width: 600px) {
  .dashboard-body .container {
    gap: 1rem;
  }
  .dashboard-body .container > div,
  .create-tag-link {
    padding: 0.5rem;
  }
  .dashboard-body .container img {
    width: 20px;
    height: 20px;
    margin-bottom: 1.2rem;
  }
  .dashboard-body .heading {
    font-size: 0.8rem;
  }
  .section-desc {
    font-size: 0.7rem;
  }
}
