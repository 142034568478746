.issuesReceived {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
.issuesReceived__nav {
  background-color: var(--getmetag-theme);
  display: flex;
  flex: 0.1;
}

.issuesReceived .issuesReceived__link {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.issuesReceived__body  {
padding: 10px;
}

.no_message {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}