:root {
  --getmetag-theme: #161521;
  --brand-title: #CCFF02;
  --text-color: #8c91a8;
  --button-hover: rgba(22,21,23,0.1);
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}
body {
  font-family: 'Nunito', sans-serif;
}
