footer {
    text-align: center;
    margin-top: 1rem;
    padding: 0.8rem;
    font-size: 1rem;
    background-color: var(--getmetag-theme);
    color: #ffffff;
    word-spacing: 1px;
  }
  footer span {
    color: var(--brand-title);
    letter-spacing: 1.1px;
  }
  .home__footer__last {
    opacity: 0.2;
  }