.create__tag {
  width: 100%;
  height: 100vh;
}
.create__tagHeader {
  background-color: var(--getmetag-theme);
  padding-top: 30px;
  padding-bottom: 20px;
}
.create__tagHeader .container {
  width: 80%;
  max-width: 1140px;
  margin: auto;
}
.create__tagbody .container {
  width: 80%;
  max-width: 1140px;
  margin: auto;
  margin-top: 20px;
}
@media (max-width: 598px) {
    .create__tagbody .container {
        width: 90%;
      }
}
    .create__tagbody .container p {
        text-align: center;
        color: #8C91A8;
        
    }
.create__tagHeader p,
.create__tagHeader h2 {
  color: #ffffff;
}
.create__tagbody form {
    margin-top: 50px;
}
