.raiseIssue {
  width: 100vw;
  height: 100vh;
}
.raiseIssue__header {
  background-color: var(--getmetag-theme);
  height: 200px;
  color: #ffffff;
  padding: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.raiseIssue__header .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.vehicle_no {
  font-size: 47px;
  font-weight: 100;
}

.raiseIssue__body {
  width: 100%;
  margin-top: 20px;
}

.raiseIssue__body .container .raiseIssue__text {
  color: #8c91a8;
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
}
@media (min-width: 700px) {
  .raiseIssue__header .container {
    font-size: 18px;
  }
}
