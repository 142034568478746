.issues {
    display: flex;
    flex-direction: column;
  }
  .issues > div {
    margin-bottom: 10px;
  }
  .issues-1,
  .issues-2 {
    border-bottom: 1px solid #f2f3f7;
    padding: 10px;
    font-size: 1rem;
    color: var(--getmetag-theme);
    
  }
  .issues-1 p,
  .issues-2 p {
    padding-bottom: 1.5rem;
  }
  .MuiSvgIcon-root.time {
    font-size: 1em !important;
    margin-right: 0.2rem;
    color: var(--brand-title);
    font-weight: bolder;
  }

  .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  small {
    font-size: 0.7rem;
    position: relative;
  }
  
  