.activate-tag-top {
    background-color: #0F0F0F;
    text-align: center;
    width: 100%;
}
.logo-container{
    position: relative;  
}

.tag-img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% auto;
    width: 50px;
}

.tag-letter{
    color:#1D1848;
    font-weight: 750;
    font-size: 24px;
    position: absolute;
    top: 3.6%;
    left: 47.6%;

}

.tag-title-white{
    color: white;
    font-weight: 800;
    margin-bottom: 0%;
    font-size: 32px;
}
.tag-title-yellow{
    color: #CCFF02;
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 5%;
}

.activate-tag-sec{
    width: 100%;
    margin: auto;
    text-align: center;
}

.tag-input{
    width: 90%;
    border: 1px solid #E5EAEE;
    border-radius: 50px;
    padding: 20px 15px;
    margin: 0% 0% 3% 0%;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 4px;
}
.tag-input::placeholder{
    text-align: center;
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #0F0F0F;
    opacity: 0.25;
}
.tag-input[type=text]:focus {
    outline: none;
  }
  .tag-input[type=email]:focus {
    outline: none;
  }
.tag-sec-title{
    font-size: 14px;
    font-weight: 550;
    margin: 35px auto;
    width: 90%;
}
.activate-btn{
    color: white;
    background-color: #0F0F0F;
    padding: 18px 15px;
    border: none;
    border-radius: 50px;
    width: 131px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    margin: 5% 0%;
}
.activate-btn-icon{
    margin-left: 10%;
    padding: 6% 0%;
}

/* //raiseIsuues Styles */

.tag-comment{
    color: white;
    font-size: 14px;
    font-weight: 300;
    margin-top: -20px;
    margin-bottom: 2% !important;
}
.issues-yellow{
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 4px;
    margin-bottom: 1% !important;
}
.issues-white{
    font-size: 16px;
    font-weight: 300;
}