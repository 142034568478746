.privacy_container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.privacy_container > p {
    word-spacing: 5px;
}
.privacy_container > h1 {
    margin-bottom: 1rem;
}
.privacy_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  background-color: #004aff;
  color: #ffffff;
}
.terms_ofservice > *,
.privacy_policy > * {
  margin-bottom: 1.4rem;
}
@media (max-width: 600px) {
    .privacy_container {
        
        padding: 15px;
        
      }
      .privacy_header {
        font-size: 11px;
        margin-bottom: 2.5rem;
      }
      .terms_ofservice > *,
      .privacy_policy > * {
        margin-bottom: 1.1rem;
        font-size: 11px;
      } 
}