.signUp {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
.signUp__nav {
 
  background-color: var(--getmetag-theme);
  display: flex;
  flex: 0.1;
}
.signUp nav ul li {
  list-style-type: none;
}
.signUp .signUp__link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
}

.signUp .container {
  flex: 1;
}
.signUp .container .text {
  color: #8c91a8;
  margin-top: 25px;
  margin-bottom: 20px;
  text-align: center;
}
.signIn__link {
  text-decoration: none;
  color: var(--getmetag-theme);
}

form .form-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
form .form-control input {
  width: 80%;
  padding: 20px 30px;
  border-radius: 30px;
  margin: 10px auto;
  background: #ffffff;
  border: 1px solid #e5eaee;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(229, 234, 238, 0.5);
  -webkit-box-shadow: none;
  border-radius: 88px;
  outline: none;
  text-align: center;
  font-size: 16px;
}
form .form-control .error {
  color: red;
  font-size: 14px;
}
form .form-control input::placeholder {
  color: #8c91a8;
  text-transform: capitalize;
  text-align: center;
}
.form__otp {
  margin-top: 10px;
  padding: 15px 20px;
  border-radius: 60px;
  background: var(--getmetag-theme);
  color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
}
.redirect__link {
  text-decoration: none;
  color: blue;
}
@media (max-width: 600px) {
  .signUp .container p {
    font-size: 12px;
    padding: 0 20px 0;
  }
  form .form-control input {
    padding: 15px 20px;
  }
  form .form-control .error {
    font-size: 12px;
  }
}

