.CreateTagSuccess__success {
    max-width: 80%;
    margin: 10px auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .CreateTagSuccess__sign {
    margin-top: -290px;
    margin-bottom: 100px;
  }
  
  .CreateTagSuccess__text {
    text-align: center;
  }
  .CreateTagSuccess__text > :first-child {
    color: var(--getmetag-theme);
    font-weight: 200;
    font-size: 25px;
    margin-bottom: 50px;
  }
  .CreateTagSuccess__text h2 {
    color: var(--getmetag-theme);
    font-size: 30px;
    margin-bottom: 20px;
  }
  .CreateTagSuccess__text > p:last-child {
    color: #8c91a8;
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 30px;
  }
  .CreateTagSuccess__done {
    margin-top: 50px;
    color: #fff;
    text-decoration: none;
    background-color: var(--getmetag-theme);
    font-size: 17px;
    padding: 11px 20px;
    border-radius: 25px;
  }
  .CreateTagSuccess__link {
    text-decoration: none;
  }
  .CreateTagSuccess__qr {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    margin-top: 18px;
    padding: 5px 20px;
    border-radius: 25px;
    border: 1px solid rgba(0, 180, 216,0.8);
    background-color: rgba(0, 180, 216,0.9);
    color: #ffffff;
    transition: all 0.3s ease-in;
    font-size: 12px;
  }
  .CreateTagSuccess__qr:hover {
    background-color: rgba(0, 180, 216,0.1);
    color: rgba(0, 180, 216,0.9);
  }
  .CreateTagSuccess__qr p {
    margin-left: 10px;
  }
  @media (max-width: 600px) {
    .CreateTagSuccess__success {
      height: 100%;
      margin: 50px auto;
    }
   
    .CreateTagSuccess__text > :first-child {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 30px;
    }
    .CreateTagSuccess__sign {
    margin-top: 0;
    margin-bottom: 80px;
  }
    .CreateTagSuccess__text h2 {
      font-size: 25px;
      margin-bottom: 20px;
    }
    .CreateTagSuccess__text > p:last-child {
      
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
  