.viewQr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 100vh;
    background: #f4f4f4;
    margin: auto;
}
.viewQr img {
    max-width: 80%;
    margin-top: 50px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    border-radius: 5px;
}
