.signIn {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
.signIn__nav {
  flex: 0.1;
  display: flex;
  background-color: var(--getmetag-theme);
  
}
.signIn nav ul li {
  list-style-type: none;
 
}
.signIn .signIn__link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.signIn .container {
  flex: 1;
}
.signIn .container p {
  color: #8c91a8;
  margin-top: 20px;
  text-align: center;
}

.signUp__link {
  text-decoration: none;
  color: var(--getmetag-theme);
}
.form__otp {
  margin-top: 10px;
  padding: 15px 20px;
  border-radius: 60px;
  background: var(--getmetag-theme);
  color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
}
/* .home__footer.SignIn {
  flex: 0.1;
} */
form .form-control .error {
  color: red;
  font-size: 14px;
}
/* MEDIA QUERIES */
@media (max-width: 600px) {
  .signIn .container p {
    font-size: 12px;
    padding: 0 20px 0;
  }
  form .form-control .error {
    font-size: 12px;
  }
}
