.signUp__success {
  max-width: 80%;
  margin: 10px auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.signUp__sign {
  margin-top: -290px;
  margin-bottom: 100px;
}
.signUp__text {
  text-align: center;
}
.signUp__text > :first-child {
  /* color: #004aff; */
  color: var(--getmetag-theme);
  font-weight: 200;
  font-size: 25px;
  margin-bottom: 50px;
}
.signUp__text h2 {
  /* color: #004aff; */
  color: var(--getmetag-theme);
  font-size: 30px;
  margin-bottom: 20px;
}
.signUp__text > p:last-child {
  color: #8c91a8;
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 30px;
}
.signUp__done {
  margin-top: 50px;
  color: #fff;
  text-decoration: none;
  background-color: var(--getmetag-theme);
  font-size: 17px;
  padding: 11px 20px;
  border-radius: 25px;
}
.SignUp__link {
  text-decoration: none;
  
}
.signUp__done.success {
  margin-top: 5px;
  margin-bottom: 30px;
}
@media (max-width: 600px) {
  .signUp__success {
    height: 100%;
    margin: 50px auto;
  }
 
  .signUp__text > :first-child {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .signUp__sign {
  margin-top: 0;
  margin-bottom: 80px;
}
  .signUp__text h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .signUp__text > p:last-child {
    
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 30px;
  }
}
