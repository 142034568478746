.home__header {
  width: 100vw;
  height: 100vh;

  background-color: var(--getmetag-theme);
}
.home__Wrapper {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0px auto;
  padding-top: 30px;
}
.header__logo {
  position: relative;
  margin-top: -20px;
}

.home__header .brand__text {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
}
.home__header .home__title {
  font-size: 5rem;
  font-weight: bolder;
  color: #ffffff;
}

.home__header .home_titleDec {
  font-weight: 200;
  font-size: 2rem;
  opacity: 0.6;
  margin-top: 20px;
  color: #fff;
  text-transform: capitalize;
}

.home__navigation {
  display: flex;
}
.home__nav {
  font-size: 1.4rem;
  letter-spacing: 1.5px;
  text-decoration: none;
  color: #fff;
  margin: 0 3rem;
  border: 2px solid #ffffff;
  padding: 1rem 3rem;
  border-radius: 2rem;
}
.home__nav:hover {
  border: 2px solid var(--brand-title);
  background-color: var(--brand-title);
  color: var(--getmetag-theme);
}

.home__productDes .home__Wrapper .home__productDesHeader {
  text-align: center;
  font-size: 1.5rem;
  padding: 2rem 0;
}

.home__productDesItems--3,
.home__productDesItems--4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  height: 13rem;
}

.home__productDesItems--3 p,
.home__productDesItems--4 p {
  width: 90%;
  margin: 0.1rem auto;
}
.home__productDesItems--3 > img,
.home__productDesItems--4 > img {
  margin-bottom: 20px;
}
.home__productDes .home__productDesHeader {
  margin-bottom: 20px;
}
.home__productDes h3 {
  margin-bottom: 20px;
}
.home__productDes p,
.home__productDes h3 {
  font-size: 12px;
}
.home__productDesItems {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 15px;
  padding: 2rem 1rem;
}
.brand__des {
  font-weight: bold;
  padding: 2rem 0;
  text-align: center;
}
@media (max-width: 750px) {
  .home__header .home__title {
    font-size: 3rem;
  }
  .home__productDesItems {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 570px) {
  .home__header .home__title {
    font-size: 2rem;
  }
  .home__header .home_titleDec {
    font-weight: 200;
    font-size: 1rem;
    margin-top: 20px;
  }
  .home__nav {
    font-size: 0.8rem;

    margin: 0 0.7rem;
  }
}

@media (min-width: 768px) {
  .home__productDes p,
  .home__productDes h3 {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .home__productDesItems--3,
  .home__productDesItems--4 {
    height: 150px;
  }
}
